<template lang="pug">
  Form(
    :title="$t('sign_in.title')"
    @save="signIn"
  )
    template(v-slot:fields)
      .d-flex.flex-row
        .form-item.label-col
          .form-item-label.d-flex.align-items-center {{ $t("sign_in.account_id") }}
          .form-item-label.d-flex.align-items-center {{ $t("sign_in.password") }}
        .form-item
          .form-item-input.d-flex.align-items-center
            BFormInput(
              type="text"
              v-model="credentials.account_id"
              placeholder="account_id"
              name="account_id"
            )
          .form-item-input.d-flex.align-items-center
            AppInputPassword(v-model="credentials.password")
      BAlert(
        :show="isAlertShown"
        variant="danger"
        dismissible
      )
        span.error {{ signInError }}
    template(v-slot:link)
      RouterLink.forgot-password(:to="{ name: 'ResetPassword' }") {{ $t("forgot_password.title") }}
    template(v-slot:button)
      AppButton.btn-confirm(
        title="sign_in.title"
        :disabled="loading"
        @click="signIn"
      )
        template(v-slot:before)
          FaIcon.m-r-10(
            v-if="loading"
            spin
            icon="spinner"
          )
</template>

<script>
  // misc
  import { api } from "@/config"
  import { isEmpty } from "lodash-es"
  import { isOrganizationAdmin, isSuperadmin } from "@/helpers/permission"
  import { isSharedInventoryEnabled } from "@/helpers/organization"
  import { AUTH_LAYOUT, MAIL_SUPPORT } from "@/config/constants"

  export default {
    layout: AUTH_LAYOUT,

    components: {
      Form: () => import("@/components/Auth/Form"),
      AppButton: () => import("@/components/elements/AppButton"),
      AppInputPassword: () => import("@/components/elements/AppInputPassword")
    },

    data() {
      return {
        credentials: {
          account_id: "",
          password: ""
        },
        loading: false,
        signInError: "",
        isAlertShown: false
      }
    },

    computed: {
      inventoryManagementPage() {
        return isSharedInventoryEnabled() ? "SharedInventoryManagement" : "InventoryManagement"
      }
    },

    methods: {
      signIn() {
        this.loading = true
        this.isAlertShown = false

        api
          .post("/auth/sign_in", this.credentials)
          .then(() => {
            this.$store.dispatch("FETCH_CURRENT_USER").then(() => {
              this.$router.push({ name: this.extractUserHistoryRoute() })
            })
          })
          .catch(({ response }) => {
            this.setErrorMessage(response)
            this.isAlertShown = true
            // need to unblock sign in button when error occured only
            this.loading = false
          })
      },

      extractUserHistoryRoute() {
        const userHistoryRoute = this.$cookie.get("lastUserRouteBeforeSignOut")
        if (userHistoryRoute) this.$cookie.delete("lastUserRouteBeforeSignOut")
        if (isSuperadmin() || isOrganizationAdmin()) return "Maestro/AccountList"

        return userHistoryRoute || this.inventoryManagementPage
      },

      setErrorMessage(response) {
        if (isEmpty(response)) {
          this.signInError = this.$t("requests.login_failed", { email: MAIL_SUPPORT })
        } else {
          const { data, status } = response

          switch (status) {
            case 401:
              this.signInError = data.errors[0]
          }
        }
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .form-item
    width: 100%
    display: flex
    flex-direction: column
    margin-top: 20px

    &.label-col
      flex-basis: 75%
      padding-right: 10px

    &-label
      height: 50px
      white-space: nowrap

    &-input
      height: 50px

      input
        border: 1px solid $default-purple

  .error
    font-size: 0.8rem
</style>
